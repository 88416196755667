import { FC, useState, createContext, Dispatch, useEffect, useContext } from 'react';
import { User, UserSkill, UserApi, Configuration } from '../core';
import { AuthContext } from '../context/AuthContext';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

interface Props {
  children: React.ReactNode;
}

interface UserData {
  department: string | undefined;
  role: string | undefined;
  skills: UserSkill[];
  hasChanged: boolean;
  dataLoaded: boolean;
}
interface UserInterface {
  userData: UserData;
  isLoading: boolean;
  setUserData: Dispatch<UserData>;
  removeUserSkill: (skill: UserSkill) => void;
  addUserSkill: (skill: UserSkill) => void;
  editUserSkill: (skillToEdit: UserSkill, editedSkill: UserSkill) => void;
  postUser: () => Promise<void>;
}

export const UserContext = createContext<UserInterface>({} as UserInterface);

const UserContextProvider: FC<Props> = ({ children }) => {
  const { user, username, checkUser } = useContext(AuthContext);
  const api = new UserApi(new Configuration({ apiKey: user && user.idToken.jwtToken }));

  const [userResponse, setUserResponse] = useState<User>();
  const [userData, setUserData] = useState<UserData>({
    department: undefined,
    role: undefined,
    skills: [],
    hasChanged: false,
    dataLoaded: false,
  });
  const [newUser, setNewUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUserApi = () => {
    checkUser();
    if (user) {
      setIsLoading(true);
      return api.getUser();
    }
  };

  const { data, isError, refetch } = useQuery('userData', getUserApi, {
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  });

  useEffect(() => {
    refetch();
  }, [user]);

  useEffect(() => {
    if (data && data.status === 200) {
      setUserResponse(data.data);
      setIsLoading(false);
    } else if ((data && data.status === 204) || isError) {
      setUserResponse({ department: '', role: '', name: '', userSkills: [] });
      setNewUser(true);
      setIsLoading(false);
    }
  }, [data, isError]);

  useEffect(() => {
    if (user) {
      Auth.currentSession();
    }

    if (userResponse) {
      setUserData({
        ...userData,
        role: userResponse.role,
        department: userResponse.department,
        skills: userResponse.userSkills || [],
        dataLoaded: true,
      });
    }
  }, [userResponse]);

  useEffect(() => {
    (async () => {
      if (newUser && userData.role && userData.department) {
        setUserData({ ...userData, hasChanged: true });
        try {
          await createUser();
          (() =>
            toast.success('Saved!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }))();
          setNewUser(false);
          return;
        } catch (err) {
          console.log(err);
        }
      }
      setUserData({ ...userData, hasChanged: false });
      setIsLoading(false);
    })();
  }, [userData.department, userData.role]);

  useEffect(() => {
    (async () => {
      if (
        !newUser &&
        userData.dataLoaded &&
        userData.role &&
        userData.department &&
        userResponse &&
        (userData.department !== (userResponse.department && userResponse.department) ||
          userData.role !== (userResponse.role && userResponse.role) ||
          JSON.stringify(userData.skills) !== JSON.stringify(userResponse?.userSkills))
      ) {
        setUserData({ ...userData, hasChanged: true });
        try {
          await postUser();
          return;
        } catch (err) {
          console.log(err);
        }
      }
      setUserData({ ...userData, hasChanged: false });
    })();
  }, [userData.department, userData.role, userData.skills]);

  const createUser = async () => {
    setUserData({ ...userData, hasChanged: false });
    try {
      let res;
      if (userData.department && userData.role) {
        res = await api.addUser({
          name: username || '',
          department: userData.department,
          role: userData.role,
          userSkills: userData.skills,
        });
      }

      if (res) {
        setUserResponse(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postUser = async () => {
    setUserData({ ...userData, hasChanged: false });

    try {
      //Checks and removes any duplicated skills.
      userData.skills = userData.skills.filter(function (element, index, self) {
        return index === self.indexOf(element);
      });

      let res;
      if (userData.department && userData.role && username) {
        res = await api.updateUser({
          name: username,
          department: userData.department,
          role: userData.role,
          userSkills: userData.skills,
        });
      }

      if (res) {
        setUserResponse(res.data);
        (() =>
          toast.success('Saved!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }))();
      }
    } catch (error) {
      (() =>
        toast.error('Error', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))();
      console.log(error);
    }
  };

  const addUserSkill = (userSkill: UserSkill): void => {
    setUserData({ ...userData, skills: userData.skills.concat(userSkill) });
  };

  const removeUserSkill = async (userSkill: UserSkill): Promise<void> => {
    const newArray = userData.skills.filter((skill) => {
      return skill !== userSkill;
    });
    setUserData({ ...userData, skills: newArray });
  };

  const editUserSkill = (userSkillToEdit: UserSkill, editedSkill: UserSkill): void => {
    const index = userData.skills.indexOf(userSkillToEdit);
    const newArray = [...userData.skills];
    newArray[index] = editedSkill;
    setUserData({ ...userData, skills: newArray });

    postUser();
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        isLoading,
        setUserData,
        removeUserSkill,
        editUserSkill,
        addUserSkill,
        postUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
