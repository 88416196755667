/* tslint:disable */
/* eslint-disable */
/**
 * Apadmi Skills
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    certificateProvider?: string;
}
/**
 * 
 * @export
 * @interface CertificationProvider
 */
export interface CertificationProvider {
    /**
     * 
     * @type {string}
     * @memberof CertificationProvider
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CertificationProvider
     */
    value: string;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    value: string;
}
/**
 * 
 * @export
 * @interface ReferenceData
 */
export interface ReferenceData {
    /**
     * 
     * @type {Array<Role>}
     * @memberof ReferenceData
     */
    Roles: Array<Role>;
    /**
     * 
     * @type {Array<Department>}
     * @memberof ReferenceData
     */
    Departments: Array<Department>;
    /**
     * 
     * @type {Array<Skill>}
     * @memberof ReferenceData
     */
    Skills: Array<Skill>;
    /**
     * 
     * @type {Array<SkillLevel>}
     * @memberof ReferenceData
     */
    SkillLevels: Array<SkillLevel>;
    /**
     * 
     * @type {Array<SkillGroup>}
     * @memberof ReferenceData
     */
    SkillGroups: Array<SkillGroup>;
    /**
     * 
     * @type {Array<Certificate>}
     * @memberof ReferenceData
     */
    Certificates: Array<Certificate>;
    /**
     * 
     * @type {Array<CertificationProvider>}
     * @memberof ReferenceData
     */
    CertificationProviders: Array<CertificationProvider>;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    value: string;
}
/**
 * 
 * @export
 * @interface Skill
 */
export interface Skill {
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Skill
     */
    skillGroup?: string;
}
/**
 * 
 * @export
 * @interface SkillGroup
 */
export interface SkillGroup {
    /**
     * 
     * @type {string}
     * @memberof SkillGroup
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SkillGroup
     */
    value: string;
}
/**
 * 
 * @export
 * @interface SkillLevel
 */
export interface SkillLevel {
    /**
     * 
     * @type {string}
     * @memberof SkillLevel
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof SkillLevel
     */
    value: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    department: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {Array<UserSkill>}
     * @memberof User
     */
    userSkills: Array<UserSkill>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    userGroups?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserForm
 */
export interface UserForm {
    /**
     * 
     * @type {string}
     * @memberof UserForm
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserForm
     */
    selectedRole: string;
    /**
     * 
     * @type {string}
     * @memberof UserForm
     */
    selectedDepartment: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserForm
     */
    selectedUserGroups: Array<string>;
    /**
     * 
     * @type {Array<UserSkill>}
     * @memberof UserForm
     */
    selectedSkills: Array<UserSkill>;
}
/**
 * 
 * @export
 * @interface UserSkill
 */
export interface UserSkill {
    /**
     * 
     * @type {string}
     * @memberof UserSkill
     */
    skill: string;
    /**
     * 
     * @type {number}
     * @memberof UserSkill
     */
    skillLevel: number;
}

/**
 * ReferenceDataApi - axios parameter creator
 * @export
 */
export const ReferenceDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferenceData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referenceOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Reference Data
         * @param {ReferenceData} referenceData Updated Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceData: async (referenceData: ReferenceData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceData' is not null or undefined
            assertParamExists('updateReferenceData', 'referenceData', referenceData)
            const localVarPath = `/reference`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(referenceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceDataApi - functional programming interface
 * @export
 */
export const ReferenceDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferenceDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferenceData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferenceData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async referenceOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.referenceOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Reference Data
         * @param {ReferenceData} referenceData Updated Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReferenceData(referenceData: ReferenceData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferenceData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReferenceData(referenceData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferenceDataApi - factory interface
 * @export
 */
export const ReferenceDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferenceDataApiFp(configuration)
    return {
        /**
         * Get Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferenceData(options?: any): AxiosPromise<ReferenceData> {
            return localVarFp.getReferenceData(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        referenceOptions(options?: any): AxiosPromise<void> {
            return localVarFp.referenceOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Reference Data
         * @param {ReferenceData} referenceData Updated Reference Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReferenceData(referenceData: ReferenceData, options?: any): AxiosPromise<ReferenceData> {
            return localVarFp.updateReferenceData(referenceData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferenceDataApi - object-oriented interface
 * @export
 * @class ReferenceDataApi
 * @extends {BaseAPI}
 */
export class ReferenceDataApi extends BaseAPI {
    /**
     * Get Reference Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataApi
     */
    public getReferenceData(options?: any) {
        return ReferenceDataApiFp(this.configuration).getReferenceData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataApi
     */
    public referenceOptions(options?: any) {
        return ReferenceDataApiFp(this.configuration).referenceOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Reference Data
     * @param {ReferenceData} referenceData Updated Reference Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDataApi
     */
    public updateReferenceData(referenceData: ReferenceData, options?: any) {
        return ReferenceDataApiFp(this.configuration).updateReferenceData(referenceData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new user
         * @param {User} user The added user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('addUser', 'user', user)
            const localVarPath = `/createUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update the current user with admin permissions
         * @param {User} user The added or updated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('adminUpdateUser', 'user', user)
            const localVarPath = `/adminUpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the selected user
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('deleteUser', 'email', email)
            const localVarPath = `/user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated user
         * @param {User} user Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (user: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/updateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEmailOptions: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('userEmailOptions', 'email', email)
            const localVarPath = `/user/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new user
         * @param {User} user The added user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add or update the current user with admin permissions
         * @param {User} user The added or updated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateUser(user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete the selected user
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updated user
         * @param {User} user Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(user: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userEmailOptions(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userEmailOptions(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Add a new user
         * @param {User} user The added user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.addUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update the current user with admin permissions
         * @param {User} user The added or updated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUser(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.adminUpdateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * delete the selected user
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Updated user
         * @param {User} user Update user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {string} email The email of the user we want to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEmailOptions(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.userEmailOptions(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Add a new user
     * @param {User} user The added user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUser(user: User, options?: any) {
        return UserApiFp(this.configuration).addUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update the current user with admin permissions
     * @param {User} user The added or updated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public adminUpdateUser(user: User, options?: any) {
        return UserApiFp(this.configuration).adminUpdateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete the selected user
     * @param {string} email The email of the user we want to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(email: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: any) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updated user
     * @param {User} user Update user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(user: User, options?: any) {
        return UserApiFp(this.configuration).updateUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {string} email The email of the user we want to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userEmailOptions(email: string, options?: any) {
        return UserApiFp(this.configuration).userEmailOptions(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUserOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/adminUpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/createUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorizer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/updateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateUserOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUserOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUserOptions(options?: any): AxiosPromise<void> {
            return localVarFp.adminUpdateUserOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserOptions(options?: any): AxiosPromise<void> {
            return localVarFp.createUserOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserOptions(options?: any): AxiosPromise<void> {
            return localVarFp.updateUserOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userOptions(options?: any): AxiosPromise<void> {
            return localVarFp.userOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersOptions(options?: any): AxiosPromise<void> {
            return localVarFp.usersOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public adminUpdateUserOptions(options?: any) {
        return UsersApiFp(this.configuration).adminUpdateUserOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserOptions(options?: any) {
        return UsersApiFp(this.configuration).createUserOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsers(options?: any) {
        return UsersApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserOptions(options?: any) {
        return UsersApiFp(this.configuration).updateUserOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userOptions(options?: any) {
        return UsersApiFp(this.configuration).userOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersOptions(options?: any) {
        return UsersApiFp(this.configuration).usersOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


