import { Dispatch, SetStateAction, useEffect, useReducer, useState, FC, useContext } from 'react';
import './UsersTable.scss';
import Modal from 'react-modal';
import { capitalizer } from '../../utils/helpers/capitalizer';
import { User as UserType, UserSkill } from '../../core';
import Button from '../Button/Button';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { sortOrderTable, SortOrder } from '../../utils/helpers/sortOrder';

interface Props {
  filteredUsers: UserType[] | undefined;
  setFilteredUsers: (Users: UserType[] | undefined) => Dispatch<SetStateAction<UserType[] | undefined>>;
}

const modalStyles = {
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
  },
};

const UsersTable: FC<Props> = ({ filteredUsers, setFilteredUsers }) => {
  const { referenceData } = useContext(ReferenceDataContext);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType>();

  const [orderBy, setOrderBy] = useState<{ sortType: string; sortOrder: SortOrder }>({
    sortType: 'table',
    sortOrder: SortOrder.Default,
  });

  useEffect(() => {
    setSortOrderHandler();
  }, [orderBy]);

  const renderSkills = (userSkills: UserSkill[]) => {
    let skillsString = '';
    userSkills &&
      userSkills.forEach((userSkill, index) => {
        const isLastItem = userSkills.length - 1 === index;
        skillsString =
          skillsString +
          getReferenceDataByValue(userSkill.skill as string, RefDataType.Skill, referenceData!).label +
          (isLastItem ? '' : ', ');
      });
    return skillsString;
  };

  const openModal = (user: UserType) => {
    setSelectedUser(user);
    setIsOpen(true);
  };

  const chevronHelper = (title: string) => {
    if (orderBy.sortType === title && orderBy.sortOrder === SortOrder.Ascend) {
      return 'table-titles active desc';
    } else if (orderBy.sortType === title) {
      return 'table-titles active';
    } else {
      return 'table-titles';
    }
  };

  //Takes the logic from setSortOrderHandler() and applies the table refresh
  const setSortOrderHandler = () => {
    if (filteredUsers && orderBy && referenceData && orderBy.sortOrder !== SortOrder.Default) {
      setFilteredUsers(
        sortOrderTable(orderBy.sortOrder, orderBy.sortType, filteredUsers, referenceData)
      );

      forceUpdate();
    }
  };

  //Checks if matches currently selected title. If so move to next sort if not reset sort order.
  const sortUsers = (selectedColumn: string) => {
    if (orderBy?.sortType === selectedColumn) {
      switch (orderBy.sortOrder) {
        case SortOrder.Ascend: {
          setOrderBy({ sortType: selectedColumn, sortOrder: SortOrder.Descend });
          break;
        }
        case SortOrder.Descend: {
          setOrderBy({ sortType: selectedColumn, sortOrder: SortOrder.Ascend });
          break;
        }
        case SortOrder.Default: {
          setOrderBy({ sortType: selectedColumn, sortOrder: SortOrder.Ascend });
          break;
        }
        default: {
          setOrderBy({ sortType: selectedColumn, sortOrder: SortOrder.Ascend });
          break;
        }
      }
    } else {
      setOrderBy({ sortType: selectedColumn, sortOrder: SortOrder.Ascend });
    }
  };

  return (
    <div className="user-table-container w-full">
      <table className="users-table w-full">
        <thead>
          <tr>
            <th className="table-titles-basic">#</th>
            <th className={chevronHelper('name')} onClick={() => sortUsers('name')}>
              Name <i className="fa-solid fa-chevron-down"></i>
            </th>
            <th className={chevronHelper('email')} onClick={() => sortUsers('email')}>
              Email <i className="fa-solid fa-chevron-down"></i>
            </th>
            <th className={chevronHelper('department')} onClick={() => sortUsers('department')}>
              Department <i className="fa-solid fa-chevron-down"></i>
            </th>
            <th className={chevronHelper('role')} onClick={() => sortUsers('role')}>
              Role <i className="fa-solid fa-chevron-down"></i>
            </th>
            <th className="table-titles-basic">Skills</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers &&
            filteredUsers.map((filteredUser: UserType, index) => {
              return (
                <tr key={index} onClick={() => openModal(filteredUser)}>
                  <td>{index + 1}</td>
                  <td>{filteredUser.name ? filteredUser.name : ' - '}</td>
                  <td>{filteredUser.email}</td>
                  <td>
                    {filteredUser.department
                      ? getReferenceDataByValue(filteredUser.department, RefDataType.Department, referenceData!).label
                      : '-'}
                  </td>
                  <td>
                    {filteredUser.role
                      ? getReferenceDataByValue(filteredUser.role, RefDataType.Role, referenceData!).label
                      : '-'}
                  </td>
                  <td>{renderSkills(filteredUser.userSkills)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={modalStyles}
        contentLabel="Employee Modal"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <h4>
            {selectedUser && selectedUser.name ? selectedUser.name : ' - '}{' '}
            {selectedUser?.role &&
              ' - ' + getReferenceDataByValue(selectedUser.role, RefDataType.Role, referenceData!).label}{' '}
            {selectedUser?.department &&
              ' - ' + getReferenceDataByValue(selectedUser.department, RefDataType.Department, referenceData!).label}
          </h4>
          <div className="modal-table-container">
            <table className="modal-table">
              <thead>
                <tr>
                  <th>Skill</th>
                  <th>Level</th>
                </tr>
              </thead>
              <tbody>
                {selectedUser?.userSkills &&
                  selectedUser.userSkills
                    .sort((a, b) => b.skillLevel - a.skillLevel)
                    .map((userSkill, index) => {
                      return (
                        <tr key={index}>
                          <td>{getReferenceDataByValue(userSkill.skill, RefDataType.Skill, referenceData!).label}</td>
                          <td>
                            {capitalizer(
                              getReferenceDataByValue(userSkill.skillLevel, RefDataType.SkillLevel, referenceData!)
                                .label
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <Button btnType="amber" clicked={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UsersTable;
