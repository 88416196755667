import { FC, useContext } from 'react';
import './Skills.scss';
import Listform from '../../components/ListForm/ListForm';
import ListOfSkills from '../../components/ListOfSkills/ListOfSkills';
import { UserContext } from '../../context/UserContext';
import Loader from '../../components/Loader/Loader';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { Department, Role } from '../../core';
import 'react-toastify/dist/ReactToastify.css';
import SkillLevelTable from '../../components/SkillLevelTable/SkillLevelTable';

const Skills: FC = () => {
  const { referenceData, referenceDataLoaded } = useContext(ReferenceDataContext);
  const { userData, isLoading, addUserSkill, removeUserSkill, editUserSkill } = useContext(UserContext);
  const { dataLoaded, department, role, skills } = userData;

  return (
    <section className="wrapper">
      <div className="page-heading">
        <h2>Skills Dashboard</h2>
      </div>
      {dataLoaded && referenceDataLoaded && !isLoading ? (
        <>
          <div className="skills-page-container">
            <article>
              <Listform />
              <h3>Your Skills</h3>
              <ListOfSkills
                userDepartment={
                  getReferenceDataByValue(department as string, RefDataType.Department, referenceData!) as Department
                }
                userRole={getReferenceDataByValue(role as string, RefDataType.Role, referenceData!) as Role}
                userSkills={skills}
                addUserSkill={addUserSkill}
                removeUserSkill={removeUserSkill}
                editUserSkill={editUserSkill}
              />
            </article>
            <div className="skills-description-table">
              <SkillLevelTable />
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default Skills;
